import React from "react"
import { themeGet } from 'styled-system'
import styled from 'styled-components'
import Hero from "../components/Hero"
import Layout from "../layouts"
import { Link, graphql, StaticQuery } from 'gatsby'
import MDXRenderer from "gatsby-mdx/mdx-renderer";

const Content = styled.div`
	width: 65%;
	margin: 0 auto;
	@media (max-width: ${themeGet('breakpoints.1')}) { 
		width: 100%;
	}

	& p, ul, li {
		font-size: 20px;
		line-height: 34px;
	}

	& h1 {
		font-size: ${themeGet('headings.9')};
		margin-bottom: 0;
		
		@media (max-width: ${themeGet('breakpoints.4')}) {
			font-size: ${themeGet('headings.8')};
		}
		@media (max-width: ${themeGet('breakpoints.2')}) {
			font-size: ${themeGet('headings.6')};
		}
		@media (max-width: ${themeGet('breakpoints.1')}) {
			margin: 0.5em 0;
		}
	}

	& h2 {
		font-size: ${themeGet('headings.7')};
		margin-bottom: 0;
		
		@media (max-width: ${themeGet('breakpoints.4')}) {
			font-size: ${themeGet('headings.6')};
		}
		@media (max-width: ${themeGet('breakpoints.2')}) {
			font-size: ${themeGet('headings.4')};
		}
		@media (max-width: ${themeGet('breakpoints.1')}) {
			margin: 0.5em 0;
		}
	}

	& h3 {
		font-size: 3em;
		font-familiy: "Graphik";
	}

	& h4 {
		font-size: 2.4em;
		font-familiy: "GraphikMedium";
	}

	& h5 {
		font-size: 2em;
	}

	& p, img {
		width: 100%;
	}
`


class Readings extends React.Component {
	constructor(props) {
		super(props)
	}

	render() {
		const {mdx} = this.props.data;
		return (
			<Layout>
				<Hero data={mdx.frontmatter} />
				<Content>
					<MDXRenderer>{mdx.code.body}</MDXRenderer>
				</Content>
		  	</Layout>
		)
	}
}

export default Readings

export const pageQuery = graphql`
  query ReadingsQuery($id: String) {
    mdx(id: { eq: $id }) {
      	id
      	frontmatter {
       		title
			templateKey
      	}
      	code {
        	body
      	}
    }
  }
`;